import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getPersonalInsightsResultList } from '@services/personalInsightsResults';

function useGetPersonalInsightQuestionnaireResults({
  sessionId,
  onSuccess,
  refetchOnWindowFocus = false,
}: PromiseCallbacks & { sessionId: string }) {
  return useFetch<{ createdAt: Date, _id: string }[]>(
    ['getPersonalInsightsResults', sessionId],
    () => getPersonalInsightsResultList(sessionId),
    {
      onSuccess,
      refetchOnWindowFocus,
    },
  );
}
  
export default useGetPersonalInsightQuestionnaireResults;
  
