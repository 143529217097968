export const routeExceptionsForSectionComponents = [
  '/login',
  '/room',
  '/responder-login',
  '/sos',
];

export const routeForFilterComponent = ['/providers', undefined]; // undefined for / route

export const routesForPersonalInsightsBanner = ['/onsite-days', '/providers', '/providers/:role/:providerId', undefined];
