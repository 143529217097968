import Analytics from '@services/Analytics';
import { SessionStorageKeys } from '@utilities/common/Storage';
import { getCookie, getUserPreferences } from 'vanilla-cookieconsent';

export const userHasConsentedForMixpanel = () => {
  const { categories } = getCookie();
  return categories?.includes('analytics');
};

export const onUserModifiesPreference = (isLoggedIn: string) => {
  const preferences = getUserPreferences();
  if (preferences.acceptedCategories.includes('analytics')) {
    Analytics.initialize();
  }
  
  sessionStorage.setItem(SessionStorageKeys.MISC.GDPR_CONSENT_UPDATED, 'true');
  
  if (!isLoggedIn) {
    sessionStorage.setItem(SessionStorageKeys.MISC.SYNC_GDPR_CONSENT_WITH_USER, 'true');
  }
  
  window.location.reload();
};