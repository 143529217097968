import { useEffect } from 'react';
import { run } from 'vanilla-cookieconsent';
import useAuth from '@hooks/useAuth';
import Analytics from '@services/Analytics';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { COOKIE_CONSENT_MAIN_CONFIG } from './shared/complaince/GDPR/config';
import { onUserModifiesPreference, userHasConsentedForMixpanel } from './shared/complaince/GDPR/utils';

function CookieConsentInitializer() {
  const { isLoggedIn } = useAuth();

  if (userHasConsentedForMixpanel()) {
    Analytics.initialize();
  }

  useEffect(() => {
    const configWithAuth = {
      ...COOKIE_CONSENT_MAIN_CONFIG,
      onFirstConsent: () => onUserModifiesPreference(isLoggedIn),
      onChange: () => onUserModifiesPreference(isLoggedIn),
    };

    run(configWithAuth);
  }, [isLoggedIn]); 
  return null; 
}

export default CookieConsentInitializer;
