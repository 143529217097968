import { ApiResponse, Trait } from '@customTypes/screener';
import { getTranslation } from '../../../../i18n/utilities';

export function translateTraits(response: ApiResponse): Trait[] {
  const { data, translationKeys } = response;
    
  return data.flatMap(personality => 
    personality.traits.map(trait => ({
      traitId: trait.traitId,
      title: getTranslation(trait.title, translationKeys) || 'Unknown Title',
      description: getTranslation(trait.description, translationKeys) || 'No description available',
      score: trait.score,
      rating: getTranslation(trait.rating, translationKeys) || 'No rating',
      scoreMeaning: getTranslation(trait.scoreMeaning, translationKeys) || 'No meaning available',
    })),
  );
}
