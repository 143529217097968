import { IOnsiteMeetingResponse, ISoonestMeeting } from '@hooks/useOnsiteData/types';
import * as React from 'react';


export interface IScreenerBannerContextData {
  showPersonalInsightsBanner: boolean;
  currentMeeting: ISoonestMeeting | null;
  updatePersonalInsightsBannerVisibility: (status: boolean) => void;
  updateMeetingList: (meetingList: IOnsiteMeetingResponse['meetings']) => void;
}


export const ScreenerBannerContext = React.createContext<IScreenerBannerContextData>(
  {} as IScreenerBannerContextData,
);
