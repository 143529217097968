import React from 'react';
import useAuth from '@hooks/useAuth';
import usePostMessage from '@hooks/usePostMessage';
import { getItemFromLocalStorage } from '@utilities/common/Storage';
import { StorageItems } from '@constants/App';
import { UserWithRoles } from '@hooks/useAuth/useAuth';
import { isProduction } from '@utilities/common/Path';
import { userHasConsentedForMixpanel } from '../../../shared/complaince/GDPR/utils';
import { MarbleInitializePayload, MarbleOptions } from './types';
import { MarbleMessage } from './constants';

export const useMarble = (uri: string, options: MarbleOptions = {}) => {
  const { userToken } = useAuth();
  const rendererRef = React.useRef<HTMLIFrameElement>(null);
  const { sendPostMessageToChild, listen } = usePostMessage();

  React.useEffect(() => {
    listen(MarbleMessage.widgetLoaded, () => {
      const user = getItemFromLocalStorage(StorageItems.USER_INFO, 'object')  as UserWithRoles;

      const ISEInitializePayload: MarbleInitializePayload = {
        uri,
        type: MarbleMessage.initialiseWidget,
        jwt: userToken || user.token,
        hostName: 'intellect-maverick',
        theme: 'intellectStandard',
        target: 'home',
        internal: true,
        locale: 'en',
        analytics: {
          enableMixpanel: userHasConsentedForMixpanel(),
          mixpanelProjectKey: (isProduction() ? process.env.REACT_APP_MIXPANEL_PROJECT_KEY_PRODUCTION : process.env.REACT_APP_MIXPANEL_PROJECT_KEY_DEVELOPMENT) as string,
        },
      };

      sendPostMessageToChild(rendererRef, ISEInitializePayload);
    });
  }, [listen, options.target, rendererRef, sendPostMessageToChild, uri, userToken]);

  const handleMessageEvent = React.useCallback(
    (event: MessageEvent) => {
      const { type } = event.data || {};
      if (type === MarbleMessage.widgetClose) {
        if (type && event.data?.data?.sessionCompleted) {
          options.onComplete?.(event.data?.data?.questionnaireResultId);
        } else {
          options.onWidgetClose?.();
        }
      }
    },
    [options],
  );

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);

  return {
    rendererRef,
  };
};
