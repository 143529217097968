import React from 'react';
import './WellbeingResultPage.scss';
import Typography from '@widgets/Typography';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetPersonalInsightQuestionnaireResultById from '@hooks/useGetPersonalInsightQuestionnaireResultById';
import Loader from '@widgets/Loader';
import { getFormattedDateTime } from '@utilities/helpers/date';
import useGetPersonalInsightQuestionnaireResults from '@hooks/useGetPersonalInsightQuestionnaireResults';
import backIcon from '../../../assets/go-back.svg';
import ViewResultsArrow from '../../../assets/view-results-arrow.svg';
import lowConfidenceEmoji from '../../../assets/low-confidence-emoji.svg';
import highConfidenceEmoji from '../../../assets/high-confidence-emoji.svg';
import mediumConfidenceEmoji from '../../../assets/medium-confidence-emoji.svg';
import { translateTraits } from './helper/wellbeingResultsData';

function WellbeingResultPage() {
  const navigate = useNavigate();
  const { resultId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source') || ''; 

  if (!resultId) {
    navigate('/personal-insights');
    return null;
  }

  const onBackButtonClick = () => {
    if (source === 'screener') {
      navigate('/personal-insights');
    } else {
      navigate(-1);
    }
  };

  const emoji = { 
    'High':highConfidenceEmoji,
    'Moderate': mediumConfidenceEmoji,
    'Low': lowConfidenceEmoji,
  };
  
  const { data, isLoading, isFetching } = useGetPersonalInsightQuestionnaireResultById({
    resultId,
  });

  const { data: reports } = useGetPersonalInsightQuestionnaireResults({
    sessionId: process.env.REACT_APP_TNS_DIMENSIONS_URI as string,
  });

  if (isLoading || isFetching) {
    return <Loader />;
  }
  

  if (!data) {
    return null;
  }

  return (
    <div className="wellbeing-result">
      <section
        className="wellbeing-result__back-button"
        role="button"
        tabIndex={0}
        onClick={() => onBackButtonClick()}
      >
        <img src={backIcon} alt="go back" />
      </section>
      <Typography weight="500" size={20} withColor="#000000" additionalStyles={{ marginTop: '10px' }}>Personal Insights - Trust and Safety Results</Typography>
      <div className="wellbeing-result__container">
        <Typography weight="400" size={14} withColor="#667085">{getFormattedDateTime(data.createdAt, 'DD MMM YYYY')}</Typography>
        {source === 'screener' && reports && reports.length > 1 && 
        <div role="button" tabIndex={0} className="wellbeing-result__view-results" onClick={() => navigate('/personal-insights/results')}>
          <Typography weight="500" size={12} withColor="#1168D1">View past results</Typography>
          <img
            src={ViewResultsArrow}
            alt="view past results"
            width="10px"
            style={{
              transform: 'rotate(270deg)',
            }}
          />
        </div>}
      </div>
      
      
      {data && translateTraits(data).map(({ title, traitId, scoreMeaning, rating, description }) => {
        if (!traitId) return null;

        return (
          <div key={traitId} className="result">
            <div className="result__header">
              <Typography weight="500" size={15} withColor="#000000">{title}</Typography>
              <Typography weight="400" size={12} withColor="#636C73" additionalStyles={{ marginBottom: '6px' }}>{description}</Typography>

              <div className={`result__confidence result__confidence--${rating.toLowerCase()}`}>
                {rating} confidence
              </div>
            </div>
            
            <div className="result__details">
              <div className="result__emoji">
                <img
                  src={emoji[rating as keyof typeof emoji]}
                  alt={`${rating} confidence`}
                />
              </div>
              <div>
                <Typography weight="500" size={14} withColor="#1A2631" additionalStyles={{ marginBottom: '4px' }}>What does this score mean?</Typography>
                <Typography weight="400" size={12} withColor="#636C73">{scoreMeaning}</Typography>
              </div>
            </div>
          </div>
        );
      })} 
    </div>
  );
}

export default WellbeingResultPage;