/* eslint-disable no-param-reassign */
import { useMarble } from '@hooks/useMarble';
import { isProduction, isUAT } from '@utilities/common/Path';
import React from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import './Screener.scss';
import { PopOver } from '@constants/App';
import WarningModal from './WarningModal';

function Screener() {
  const navigate = useNavigate();
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [resultId, setResultId] = React.useState('');

  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      const checkIse = document.querySelector(`iframe[data-ise=${PopOver.MarbleIFrame}]`) || false;
      return !resultId && !showWarningModal && checkIse && currentLocation.pathname !== nextLocation.pathname;
    },
  );

  React.useEffect(() => {
    if (resultId) {
      if (blocker && blocker.proceed) {
        blocker.proceed();
      }
      navigate(`/personal-insights/result/${resultId}?source=screener`);
    }
  }, [resultId]);

  const { rendererRef } = useMarble(`session/${process.env.REACT_APP_TNS_DIMENSIONS_URI}` as string, {
    onWidgetClose: () => {
      setShowWarningModal(true);
    },
    onComplete: (id) => {

      setResultId(id);
    },
  });

  const getMarbleSrcUrl = () => {
    if (isProduction()) {
      return process.env.REACT_APP_MARBLE_PRODUCTION_URI;
    } if (isUAT()) {
      return process.env.REACT_APP_MARBLE_UAT_URI;
    }
    return process.env.REACT_APP_MARBLE_DEVELOPMENT_URI;

  };

  return (
    <div className="screener">

      {(blocker.state === 'blocked' || showWarningModal) && (
        <WarningModal
          onCancel={() => {
            if (blocker && blocker.proceed) {
              blocker.proceed();
            }
            if (showWarningModal) {
              navigate('/personal-insights');
            }
          }}
          onConfirm={() => {
            if (blocker && blocker.reset) { blocker.reset(); }
            if (showWarningModal) { setShowWarningModal(false); }
          }}
        />
      )}

      <iframe
        ref={rendererRef}
        className="screener__marble"
        src={getMarbleSrcUrl()}
        data-ise={PopOver.MarbleIFrame}
        title="session experience"
      />
    </div>
  );
}

export default Screener;