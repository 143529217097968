import * as React from 'react';
import { getTimeZone } from '@utilities/common/Date';
// import useAuth from '@hooks/useAuth';
import { getCountryFromTimezone } from '@utilities/common/Timezone';
import { TimezoneByCountry } from '@constants/timezones';
import { AllProvidersCountryId, ProviderRoles } from '@constants/provider';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import useAuth from '@hooks/useAuth';
import { getCityFromLocation } from '@utilities/helpers/locationOfResidence';
import { FilterContext, IFilterContextData } from './FilterContext';

export interface IFilterContextProvider {
  children: React.ReactNode;
}
export function getInitialRole(userData:any) {
  if (userData?.addOns?.coachEnabled) {
    return ProviderRoles.COACHING;
  }

  if (userData?.addOns?.clinicalEnabled) {
    return ProviderRoles.CLINICAL;
  }

  return '';
}
export function FilterContextProvider({ children }: IFilterContextProvider) {
  const currentCountry = getCountryFromTimezone(getTimeZone());
  const {
    currentUserData: { userData },
  } = useCurrentUserContext();

  const { user } = useAuth();

  const getInitialLocation = (location: string): string | null => {
    if (!user?.isEmployee) {
      return 'all_locations';
    }
    return getCityFromLocation(location);
  };
  
  const initialRoleValue = React.useMemo(() => getInitialRole(userData), [userData]);
  const initialLocation = React.useMemo(() => getInitialLocation(userData?.profile?.misc?.region), [userData, user]);

  const headerRef = React.useRef<HTMLDivElement>(null);
  const [filterContextData, setFilterContextData] = React.useState<
  Omit<IFilterContextData, 'updateFilterData'>
  >({
    filterData: {
      meetingType: '0',
      providerRole: initialRoleValue,
      employeeSelectedCountry: initialLocation || '',
      timezone: getTimeZone(),
      country: currentCountry || TimezoneByCountry[0],
      providerSelectedCountry: AllProvidersCountryId,
      languages: 'en',
      goals: [],
    },
    isProfile: false,
    isRoleFilterDisabled: false,
    headerRef,
  });

  const updateFilterData = (
    filterData: Partial<Omit<IFilterContextData, 'updateFilterData'>>,
  ) => {
    setFilterContextData((prev) => ({
      ...prev,
      ...filterData,
    }));
  };

  React.useEffect(() => {
    updateFilterData({
      filterData: {
        ...filterContextData.filterData,
        providerRole: initialRoleValue,
        employeeSelectedCountry: initialLocation || '',
      },
    });
  }, [initialRoleValue, initialLocation]);

  const filterContextValues = React.useMemo(
    () => ({
      updateFilterData,
      ...filterContextData,
    }),
    [filterContextData, updateFilterData],
  );

  return (
    <FilterContext.Provider value={filterContextValues}>
      {children}
    </FilterContext.Provider>
  );
}
