import { PromiseCallbacks } from '@hooks/useFetch';
import { signOut } from '@services/auth';
import { useMutation } from 'react-query';


export default function useSignOut(
  { onSuccess, onError }: PromiseCallbacks,
) {
  return useMutation(() => signOut(), {
    onSuccess,
    onError,
  });
}