import * as React from 'react';
import useAuth from '@hooks/useAuth';
import Typography from '@widgets/Typography';
import SelectBox from '@widgets/SelectBox';
import MultiSelect from '@widgets/MultiSelect';
import { useCurrentUserContext } from '@hooks/useCurrentUserContext';
import { IFilterDataType } from 'src/view/screens/Provider/Home/ProviderList/types';

import {
  VirtualProviderRoleItems,
  ProviderRoles,
  InPersonProviderRoleItems,
} from '@constants/provider';
import { ITimezoneByCountry, TimezoneByCountry } from '@constants/timezones';
import { getCountryDataFromCountryCode } from '@utilities/common/Timezone';
import DisclaimerCard from '@widgets/DisclaimerCard';
import InfoCircle from '@widgets/CountrySpecificDropdown/assets/InfoCircle';
import CountrySpecificDropdown from '@widgets/CountrySpecificDropdown/CountrySpecificDropdown';
import { getCityFromLocation } from '@utilities/helpers/locationOfResidence';
import { AnimatePresence, motion } from 'framer-motion';
import { LORList } from '../../../shared/locationList';
import {
  MeetingTypeList,
} from '../../screens/Provider/Home/ProviderList/Constant/ProviderListConstant';
import useMatchConfig from '../../screens/Provider/Home/hooks/useMatchConfig';
import useGetProviderCountriesList from '../../screens/Provider/Home/hooks/useGetProviderCountriesList';
import useFilterContext from './context/useFilterContext';
import './FilterSection.scss';
import { getInitialRole } from './context/FilterContextProvider';
import filterIcon from '../../../assets/filter.svg';

export default function FilterSection() {
  const { user } = useAuth();
  const {
    currentUserData: { userData, currentUserConfig, isLoading: isUserLoading },
  } = useCurrentUserContext();
  const { data: matchConfig } = useMatchConfig(!user.isEmployee);
  const {
    filterData,
    updateFilterData,
    isProfile,
    isRoleFilterDisabled,
    headerRef,
  } = useFilterContext();

  const [filtersExpanded, setFiltersExpanded] = React.useState(true);

  const {
    data: providerCountriesList,
    isFetching: isProviderCountriesListFetching,
  } = useGetProviderCountriesList(
    user.id,
    {
      engagement: filterData.meetingType || '0',
      role: [filterData.providerRole || '0'],
    },
    !user.isEmployee,
  );

  const AvailableMeetingType = React.useMemo(() => {
    // if (userData?.config?.ALLOW_F2F && user.isCareNavigator) {
    //   return MeetingTypeList;
    // }
    const enabledMeetingType = ['0'];
    if (userData?.config?.ALLOW_F2F) enabledMeetingType.push('1');
    if (user.isCareNavigator) enabledMeetingType.push('10');

    return MeetingTypeList.filter((item) =>
      enabledMeetingType.includes(item.value),
    );
  }, [userData]);
  const onFilterChange = (data: Partial<IFilterDataType>) => {
    updateFilterData({
      filterData: {
        ...filterData,
        ...data,
        ...(data?.meetingType && {
          providerRole:
            data.meetingType === ProviderRoles.INTELLECTCLINICOFFSITE
              ? ProviderRoles.INTELLECTCLINICOFFSITE
              : getInitialRole(userData),
        }),
      },
    });
  };

  const availableProviderRole = React.useMemo(() => {
    let filters = VirtualProviderRoleItems;
    if (
      user.isCareNavigator &&
      filterData.meetingType === ProviderRoles.INTELLECTCLINICOFFSITE
    ) {
      filters = InPersonProviderRoleItems;
    } else {
      if (!userData?.addOns?.coachEnabled) {
        filters = VirtualProviderRoleItems.filter(
          (f) => f.value !== ProviderRoles.COACHING,
        );
      }
      if (!userData?.addOns?.clinicalEnabled) {
        filters = filters.filter((f) => f.value !== ProviderRoles.CLINICAL);
      }
    }
    return filters;
  }, [userData, filterData.meetingType]);

  return (
    <div
      ref={headerRef}
    >
      <div className={`header-section ${
        !user.isEmployee && !isProfile ? '' : 'provider-profile'
      }`}>
        <div className="left-headers">
          <Typography weight="500" color="primary" size={24}>
          Select a provider to start your journey
          </Typography>
          <Typography weight="400" color="secondary" size={16}>
          (Session booking for {user.email})
          </Typography>
        </div>
        <div
          role="button"
          tabIndex={0}
          className={`filters-collapsible-toggle ${
            filtersExpanded ? 'open' : 'closed'
          }`}
          onClick={() => setFiltersExpanded(!filtersExpanded)}
        >
          <div className="title">Filters</div>
          <img src={filterIcon} alt="expand filters" />
        </div>
        <div
          className={`filters-and-search ${filtersExpanded ? 'open' : 'closed'}`}
        >
          {currentUserConfig?.showMeetingTypeFilter && (
            <SelectBox
              id="filter-meetingType"
              disabled={isRoleFilterDisabled}
              selectedValue={filterData.meetingType}
              onChange={(value) => onFilterChange({ meetingType: value })}
              values={AvailableMeetingType}
            />
          )}
          {!isUserLoading && (
            <SelectBox
              id="filter-provider-role"
              disabled={isRoleFilterDisabled}
              selectedValue={filterData.providerRole}
              onChange={(value) => {
                onFilterChange({ providerRole: value });
              // onRoleChange?.(value);
              }}
              values={availableProviderRole}
            />
          )}
          {!user.isEmployee && !isProfile && (
            <>
              <MultiSelect
                enableSelectboxSearch
                options={
                  matchConfig?.goals.map((l) => ({
                    label: l.areaExpertise,
                    key: l.id,
                  })) || []
                }
                initialValues={filterData.goals}
                onClose={(selectedValues) =>
                  onFilterChange({ goals: selectedValues })
                }
                placeholder="Goals"
                maxSelection={3}
              />
              <SelectBox
                values={
                  matchConfig?.languages.map((l) => ({
                    label: l.name,
                    value: l.id,
                  })) || []
                }
                onChange={(value) => onFilterChange({ languages: value })}
                selectedValue={filterData.languages}
              />
              <SelectBox
                disabled={isProviderCountriesListFetching}
                id="filter-provider-country"
                selectedValue={String(filterData.providerSelectedCountry)}
                values={
                  providerCountriesList
                    ? providerCountriesList.map((t) => ({
                      label: t.countryName,
                      value: String(t.countryId),
                    }))
                    : []
                }
                onChange={(value) =>
                  onFilterChange({ providerSelectedCountry: Number(value) })
                }
                minWidth="150px"
              />
              <SelectBox
                id="filter-country"
                selectedValue={filterData.country.code}
                values={TimezoneByCountry.map((t) => ({
                  label: t.name,
                  value: t.code,
                }))}
                onChange={(value) => {
                  const country = getCountryDataFromCountryCode(
                    value,
                  ) as ITimezoneByCountry;
                  onFilterChange({
                    country,
                    timezone: country.timezones[0],
                  });
                }}
              />
              <SelectBox
                id="filter-country-timezone"
                selectedValue={filterData.timezone}
                values={filterData.country.timezones.map((t) => ({
                  label: t,
                  value: t,
                }))}
                onChange={(value) => onFilterChange({ timezone: value })}
              />
            </>
          )}
          {user.isEmployee &&
          !isProfile &&
          currentUserConfig?.showUserLocationFilter && (
            <CountrySpecificDropdown
              placeholder="Choose location"
              data={LORList}
              selectedValue={filterData.employeeSelectedCountry || ''}
              fontSize={13}
              onChange={(value) =>
                onFilterChange({ employeeSelectedCountry: value })
              }
              disclaimerHidden
              inlineSearch
              minWidth="170px"
              dropDownMinWidth="220px"
              placement="left"
              dropdownClassname="location-dropdown"
            />
          )}
        </div>
      </div>
      <AnimatePresence mode="wait">
        {user?.isEmployee && 
        !isProfile &&
       userData?.profile?.misc?.region &&
       getCityFromLocation(userData?.profile?.misc?.region) &&
       filterData?.employeeSelectedCountry &&
       getCityFromLocation(userData?.profile?.misc?.region) !== filterData?.employeeSelectedCountry &&
       <motion.div
         initial={{ opacity: 0, height: 0 }}
         animate={{ opacity: 1, height: 'auto' }}
         exit={{ opacity: 0, height: 0 }}
         transition={{ duration: 0.3, ease: 'easeInOut' }}
         style={{ overflow: 'hidden' }}
       >
         <DisclaimerCard
           subheading="Oops! You’ve filtered to a different location. Please update the filter to find the right clinician"
           cardVariant="info"
           icon={ <div style={{ color: '#64481D', marginTop: '4px' }}>
             <InfoCircle />
           </div>}
           margin="0px 0px 30px 0px"
           padding="6px 12px"
           subHeadingFontSize={12}
           fontColor="#64481D"
           customClass="location-disclaimer"
           actionButton={{
             text: 'Change location',
             onClick: () => {
               onFilterChange({ employeeSelectedCountry: getCityFromLocation(userData?.profile?.misc?.region) || '' });
             },
           }}
         />
       </motion.div>
        }
      </AnimatePresence>
    </div>
  );
}
