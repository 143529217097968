import * as React from 'react';

function InfoCircle() {
  return <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0007 1.66732C5.41732 1.66732 1.66732 5.41732 1.66732 10.0007C1.66732 14.584 5.41732 18.334 10.0007 18.334C14.584 18.334 18.334 14.584 18.334 10.0007C18.334 5.41732 14.584 1.66732 10.0007 1.66732Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 13.333V9.16634" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.0039 6.66602H9.99642" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
}
export default InfoCircle;
  