import { ApiResponse } from '@customTypes/screener';
import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getPersonalInsightsResultById } from '@services/personalInsightsResults';

function useGetPersonalInsightQuestionnaireResultById({
  resultId,
  onSuccess,
  refetchOnWindowFocus = false,
}: PromiseCallbacks & { resultId: string }) {
  return useFetch<ApiResponse>(
    ['getPersonalInsightsResultById', resultId],
    () => getPersonalInsightsResultById(resultId),
    {
      onSuccess,
      refetchOnWindowFocus,
    },
  );
}
  
export default useGetPersonalInsightQuestionnaireResultById;
  
