import * as React from 'react';
import Typography from '@widgets/Typography';
import './DisclaimerCard.scss';

interface IDisclaimerCardProps {
  heading?: string;
  subheading: string | React.ReactNode;
  cardVariant?: 'info';
  headingPlacement?: 'centre' | 'start';
  margin?: string;
  padding?: string;
  icon?: React.ReactNode;
  link?: { text: string; href: string };
  subHeadingFontSize?: number
  borderRadius?: string
  fontColor?: string
  customClass?: string;
  actionButton?: { text: string; onClick: () => void };
}

function DisclaimerCard({
  heading,
  subheading,
  cardVariant = 'info',
  headingPlacement = 'start',
  margin = '0px',
  padding = '16px',
  borderRadius = '',
  subHeadingFontSize = 12,
  fontColor =  '',
  customClass = '',
  icon,
  link,
  actionButton,
}: IDisclaimerCardProps) {
  return (
    <section className={`disclaimer-card-container ${cardVariant} ${customClass}`} style={{ margin, padding, borderRadius }}>
      {icon && <div className="icon-container">{icon}</div>}
      <div className="content">
        {heading && (
          <Typography
            size={14}
            weight="500"
            withColor="#865205"
            additionalStyles={{ 
              marginBottom: '8px',
              textAlign: headingPlacement === 'centre' ? 'center' : 'left',
            }}
          >
            {heading}
          </Typography>
        )}
        <Typography size={subHeadingFontSize} weight="400" withColor={fontColor || '#344054'}>
          {subheading}
          {link && (
            <> {' '}
              <a href={link.href} className="disclaimer-link" target="_blank" rel="noreferrer">
                {link.text}
              </a>
            </>
          )}
        </Typography>
      </div>
      {actionButton && (
        <button type="button" className="disclaimer-action-button" onClick={actionButton.onClick}>
          {actionButton.text}
        </button>
      )}
    </section>
  );
}

export default DisclaimerCard;
