import React from 'react';
import './ChatAssignmentPreview.scss';
import Button from '@widgets/Button';
import { useNavigate } from 'react-router-dom';
import useLayout from '@hooks/useLayout';
import { getFormattedDateTime } from '@utilities/helpers/date';
import Assignment from '../../../../../assets/Assignment.svg';

interface ChatAssignmentPreviewProps {
  title: string;
  assignedDate: Date;
}

function ChatAssignmentPreview({
  title,
  assignedDate,
}: ChatAssignmentPreviewProps) {

  const navigate = useNavigate();
  const { updateChatWindowSettings } = useLayout();
  return ( 
    <div className="chat-assignment">
      <div className="chat-assignment__card">
        <div className="chat-assignment__header">
          <img
            src={Assignment}
            alt="Assignment Icon"
            className="chat-assignment__icon"
          />
          <div>
            <div className="chat-assignment__title">{title}</div>
            <div className="chat-assignment__date">Assigned on {getFormattedDateTime(assignedDate, 'DD MMM')}</div>
          </div>
        </div>
        <Button
          width="100%"
          variant="blue"
          label="Start screener"
          onClick={() => {
            navigate('/personal-insights/screener');
            updateChatWindowSettings({ show: false });
          }}
        />
      </div>
    </div>);
}

export default ChatAssignmentPreview;
