import { CheckConsentRequest, ConsentUpdateRequest } from '@customTypes/request/common';
import Config from '../config';
import NetworkClient from './Network';

const client = new NetworkClient();

const ErrorMessages = {
  generic: 'Unable to perform the consent action, please try again!',
};

export const checkConsent = async (request: CheckConsentRequest) => {
  const response = await client.doPost(Config.api.consent.checkConsent, request);

  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const updateConsent = async (request: ConsentUpdateRequest) => {
  const response = await client.doPost(
    Config.api.consent.updateConsent,
    request,
  );

  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const updateGDPRConsent = async (request: ConsentUpdateRequest) => {
  const response = await client.doPut(
    Config.api.consent.GDPRConsent,
    request,
  );

  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};
