import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Typography from '@widgets/Typography';
import useGetPersonalInsightQuestionnaireResults from '@hooks/useGetPersonalInsightQuestionnaireResults';
import Loader from '@widgets/Loader';
import { getFormattedDateTime } from '@utilities/helpers/date';
import backIcon from '../../../assets/go-back.svg';
import resultCardArrow from '../../../assets/chevron-right.svg';
import './ResultsList.scss';


function ResultsList() {
  const navigate = useNavigate();


  const { data: reports, isLoading, isFetching } = useGetPersonalInsightQuestionnaireResults({
    sessionId: process.env.REACT_APP_TNS_DIMENSIONS_URI as string,
  });

  if (isLoading || isFetching) {
    return <Loader />;
  }

  const onBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className="previous-results">
      <section
        role="button"
        tabIndex={0}
        onClick={() => onBackButtonClick()}
        className="previous-results__back-button"
      >
        <img src={backIcon} alt="go back" />
      </section>
      <Typography weight="500" size={24} withColor="#101828" additionalStyles={{ margin: '20px 0px' }}>Previous results</Typography>
      <div>
        {reports && reports.map(({ createdAt, _id }) => (
          <motion.div
            key={_id}
            className="previous-results__result-item"
            whileHover={{ scale: 1.01 }}
            onClick={() => navigate(`/personal-insights/result/${_id}`)}
          >
            <div>
              <Typography weight="500" size={16} withColor="#101828">Personal Insights report</Typography>
              <div><Typography weight="400" size={12} withColor="#636C73" additionalStyles={{ marginTop: '3px' }}>{getFormattedDateTime(createdAt, 'DD MMM YYYY')}</Typography></div>
            </div>
            <img
              src={resultCardArrow}
              alt="view result"
              style={{
                transform: 'rotate(90deg)',
              }}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default ResultsList;
