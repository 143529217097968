/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './PersonalInsights.scss';
import { useNavigate } from 'react-router-dom';
import Typography from '@widgets/Typography';
import useGetPersonalInsightQuestionnaireResults from '@hooks/useGetPersonalInsightQuestionnaireResults';
import Loader from '@widgets/Loader';
import { getFormattedDateTime } from '@utilities/helpers/date';
import ViewResultsArrow from '../../../assets/view-results-arrow.svg';
import resultCardArrow from '../../../assets/chevron-right.svg';
import ScreenerSvg from '../../../assets/screener.svg';

function PersonalInsights() {
  const navigate = useNavigate();
  const [faqOpen, setFaqOpen] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    if (index === faqOpen) {
      setFaqOpen(null);
    } else {
      setFaqOpen(index);
    }
  };

  const faqs = [
    {
      question: 'What is the "Personal Insights – Trust and Safety" screener?',
      answer:
        <>
          The Personal Insights - Trust and Safety screener is a quick and easy tool designed to check in on how you&apos;re feeling across different areas of your life—like your mental, emotional, and physical health. It helps identify strengths, challenges, and areas where you might need a little extra support. Think of it as a helpful snapshot of your overall wellbeing.
          <div style={{ marginTop: '20px' }}>
              Remember, this is a non-clinical, non-diagnostic tool — it’s meant for self-reflection and guidance, not as a substitute for professional advice or care. In addition, based on clinical judgements, your clinician may choose additional tools or surveys to best support you in the session.
          </div>
        </>,
    },
    {
      question: 'How often should I take the “Personal Insights - Trust & Safety” screener?',
      answer: 'While we recommend taking the “Personal Insights - Trust & Safety” screener every two weeks for consistent tracking, you can adjust the frequency as needed based on your individual circumstances and how you are feeling.',
    },
    {
      question: 'How should I interpret the results? ',
      answer: <>
        <div>Your results are organised into three key areas:</div>
        <li><b>Mental wellbeing</b> (your emotional state at work)</li>
        <li><b>Employee experience</b> (your overall sense of fulfilment, stability, and balance at work)</li>
        <li><b>Psychological strength</b> (your sense of purpose and resilience in your work)</li>
        <div style={{ marginTop: '15px' }}>After each attempt, you&apos;ll see a score for each area, indicating your level of confidence.</div>
        <li><b>High confidence:</b> Your results indicate a strong foundation in the particular area</li>
        <li><b>Moderate confidence:</b> Your results suggest that you are doing fairly well in the particular area but it can be better</li>
        <li><b>Low confidence:</b> Your results indicate that a particular area may be an area of concern and requires attention</li>

      </>
      ,
    },
    {
      question: 'What are the next best steps?',
      answer: <>
      If you&apos;re already matched with a clinician, sharing your results will enable them to offer a deeper understanding of your scores and provide tailored support to improve your wellbeing.
        <div style={{ marginTop: '20px' }}>
        If you&apos;re new to our services, we recommend you to connect with a clinician through <span onClick={()=> navigate('/')} className="query-email link">virtual counselling</span> or <span onClick={()=> navigate('/onsite-days')} className="query-email link">onsite days</span> to start your care journey and track your wellbeing progress. 
        </div>
      </>,
    },
    {
      question: 'How does the clinician access the results?',
      answer: 'Clinicians can view your auto-assigned screener responses via their dashboard.',
    },
    {
      question: 'How can the employee share the results with the clinician?',
      answer: 'Your clinician will already be able to view your results on the screener if it was auto assigned to you. However, if you self attempted the screener and would like to share it with your clinician, you can take a screenshot of your result and share it to your clinician via the chat function.',
    },
  ];

  const { data: reports, isLoading, isFetching } = useGetPersonalInsightQuestionnaireResults({
    sessionId: process.env.REACT_APP_TNS_DIMENSIONS_URI as string,
  });

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <div className="insights-container">
      {/* Header */}
      <div className="insights-header">
        <div className="header-text">
          <div className="header-badge">
            <Typography weight="600" size={12} withColor="#CA9C1A">New</Typography>
          </div>
          <Typography lineHeight="38px" weight="500" size={20} withColor="#000000">Personal Insights - Trust and Safety</Typography>
          <Typography weight="400" size={14} withColor="#1A2631">Take the next step in your mental wellbeing journey by taking the 5 minute screener, designed specifically for <strong>you!</strong></Typography>
          <button
            type="button"
            onClick={() => navigate('/personal-insights/screener')}
            className="start-button"
          >
            Start screener
          </button>
        </div>
        <div className="header-image">
          <img src={ScreenerSvg} alt="start-screener" />
        </div>
      </div>

      {/* View Past Results */}
      {reports && reports.length ? 
        <div className="past-results">
          <div className="results-header">
            <Typography weight="500" size={20} withColor="#101828">View past results</Typography>
            {reports.length > 3 && 
          <div className="view-results" onClick={() => navigate('/personal-insights/results')}>
            <Typography weight="500" size={12} withColor="#1168D1">View all results</Typography>
            <img
              src={ViewResultsArrow}
              alt="view all results"
              width="10px"
              style={{
                transform: 'rotate(270deg)',
              }}
            />
          </div>}
       
          </div>
          <div className="results-list">
            {reports.slice(0, 3).map(({ createdAt, _id }) => (
              <motion.div
                key={_id}
                className="result-item"
                whileHover={{ scale: 1.01 }}
                onClick={() => navigate(`/personal-insights/result/${_id}`)}
              >
                <div>
                  <Typography weight="500" size={14} withColor="#000000">Personal Insights report</Typography>
                  <div><Typography weight="400" size={12} withColor="#636C73" additionalStyles={{ marginTop: '3px' }}>{getFormattedDateTime(createdAt, 'DD MMM YYYY')}</Typography></div>
                </div>
                <img
                  src={resultCardArrow}
                  alt="view result"
                  style={{
                    transform: 'rotate(90deg)',
                  }}
                />
              </motion.div>
            ))}
          </div>
        </div> : null}

      {/* FAQ Section */}
      <div className="faq-section">
        <Typography weight="500" size={20} withColor="#101828" 
          additionalStyles={{ 
            marginBottom: '4px',
          }}>
              Frequently Asked Questions
        </Typography>
        <Typography weight="500" size={12} withColor="#636C73" additionalStyles={{ 
          marginBottom: '14px',
        }}
        >
            Got a question or need help? Contact <span className="query-email">tiktok@intellect.co</span>
        </Typography>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="faq-item"  >
              <div
                className="faq-question"
                onClick={() => toggleFaq(index)}
              >
                <Typography weight="500" size={14} withColor="#000000" >
                  {faq.question}
                </Typography>
                <img
                  src={resultCardArrow}
                  alt="expand"
                  style={{
                    transform: faqOpen === index ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              </div>
              <AnimatePresence mode="wait">
                {faqOpen === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                    style={{ overflow: 'hidden' }}
                  >
                    <div className="faq-answer">{faq.answer}</div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PersonalInsights;
