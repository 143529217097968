import { isProduction, isUAT } from '@utilities/common/Path';
import React from 'react';

type ListenType = {
  type: string;
};

const getMarbleSrcUrl = () => {
  let url = '';
  if (isProduction()) {
    url = process.env.REACT_APP_MARBLE_PRODUCTION_URI || '';
  } else if (isUAT()) {
    url = process.env.REACT_APP_MARBLE_UAT_URI || '';
  } else {
    url = process.env.REACT_APP_MARBLE_DEVELOPMENT_URI || '';
  }
  if (!url) {
    console.warn('Marble source URL is undefined! Check environment variables.');
  }

  return url.replace(/\/$/, '');
};

function usePostMessage() {
  const sendPostMessageToParent = (message: string | Record<string, any>) => {
    window.parent.postMessage(message, getMarbleSrcUrl());
  };

  const sendPostMessageToChild = (ref: React.RefObject<HTMLIFrameElement>, message: string | Record<string, any>) => {
    ref.current?.contentWindow?.postMessage(message, getMarbleSrcUrl());
  };

  const listen = <T extends ListenType>(message: string, callback: (event: MessageEvent<T>) => void) => {
    window.addEventListener('message', (event: MessageEvent<T>) => {
      if (event.origin !== getMarbleSrcUrl()) return;
      if (event.data.type === message) {
        callback(event);
      }
    });
  };

  return {
    listen,
    sendPostMessageToParent,
    sendPostMessageToChild,
  };
}

export default usePostMessage;
