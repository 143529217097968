import * as React from 'react';
import './Footer.scss';
import { showPreferences } from 'vanilla-cookieconsent';
import Config from '../../../config/network/pathConfig';

function Footer() {
  const handleManagePreferences = () => {
    showPreferences();
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-copyright">© {new Date().getFullYear()} The Intellect Company. All rights reserved.</p>
        <div className="footer-links">
          <a target="_blank" rel="noopener noreferrer" href={Config.externalLinks.privacyPolicy}>Privacy Policy</a> | 
          <a target="_blank" rel="noopener noreferrer" href={Config.externalLinks.termsOfService}>Terms of Service</a> | 
          <button type="button" className="manage-preferences" onClick={handleManagePreferences}>
            Manage Preferences
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
