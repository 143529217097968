import { Country, LORList } from '../../shared/locationList';

export const getCityFromLocation = (location: string, locationList?: Country[]): string | null => {
  if (!location) {
    return null;
  }
  const parts = location.split(', ');
  const countryName = parts[parts.length - 1]; // Last part is the country name
  const cityName = parts.slice(0, parts.length - 1).join(', '); // Rest is the city name
  
  const selectedCountry = (locationList || LORList).find(country => country.country.toLowerCase() === countryName.toLowerCase());
  if (!selectedCountry) return null;
    
  const selectedCity = selectedCountry.city.find((city) => city.name.toLowerCase() === cityName.toLowerCase());
  return selectedCity ? selectedCity.name : null;
};

export const getCountryCodeByCityName = (cityName: string): string | null => (
  LORList.find(({ city }) => 
    city.some(({ name }) => name.toLowerCase() === cityName.toLowerCase()),
  )?.code || null
);

export const getCityAndCountry = (cityName: string): string | null => {
  const selectedCountry = LORList.find(country => 
    country.city.some((city) => city.name.toLowerCase() === cityName.toLowerCase()),
  );
  
  if (selectedCountry) {
    const city = selectedCountry.city.find((location) => location.name.toLowerCase() === cityName.toLowerCase());
    return city ? `${city.name}, ${selectedCountry.country}` : null;
  }
    
  return null;
};

export const getCityIdFromName = (cityName: string, locations: Country[]): number[] | null => {
  const country = locations.find(loc => 
    loc.city.some(city => city.name === cityName),
  );

  if (!country) return null;

  const city = country.city.find(loc => loc.name === cityName);

  return city?.id ?? null;
};

export const getCityNameFromId = (cityId: number[], locations: Country[]): string | null => {
  const country = locations.find(loc =>
    loc.city.some(city => cityId.every(id => city.id && city.id.includes(id))),
  );

  const city = country?.city.find(loc => cityId.every(id => loc.id && loc.id.includes(id)));

  return city ? city.name : null;

};