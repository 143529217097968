import * as CookieConsent from 'vanilla-cookieconsent';

import Config from '../../../config/network/pathConfig';
import { CURRENT_COOKIE_CONSENT_REVISION } from './constants';

export const COOKIE_CONSENT_MAIN_CONFIG: CookieConsent.CookieConsentConfig = {
  revision: CURRENT_COOKIE_CONSENT_REVISION,
  categories: {
    functional: {
      enabled: true,
      readOnly: true,
    },
    analytics: {
      enabled: false,
      readOnly: false,
    },
  },

  guiOptions: {
    consentModal: {
      layout: 'cloud',
      position: 'bottom center',
    },
  },

  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: '<h3 style="font-size: larger; margin-top: 8px;">We use cookies</h3>',
          description: `Intellect needs cookies to keep its websites reliable and secure, personalise content for you and to analyse how the websites are used.<br/><br/>
              You may find additional details of these cookies in the “Manage cookies” section.`,
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage individual preferences',
          footer: `
            <a target="_blank" style="text-decoration: underline; color: #388BF2;" rel="noopener noreferrer" href="${Config.externalLinks.privacyPolicy}">Privacy Policy</a>
            <span style="margin: 0 10px; color: background: #1C4679; opacity: 0.5">|</span>
            <a target="_blank" style="text-decoration: underline; color: #388BF2; margin: 0" rel="noopener noreferrer" href="${Config.externalLinks.termsOfService}">Terms and Conditions</a>          `,
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Save preferences',
          closeIconLabel: 'Close modal',
          sections: [
            {
              title: 'Cookie Usage',
              description:
                'Intellect needs cookies to keep its websites reliable and secure, personalise content for you and to analyse how the websites are used.',
            },
            {
              title: 'Functional cookies',
              description:
                'We use these cookies to store essential information and for the website’s security. These cookies are used for the website’s functionality and cannot be disabled',
              linkedCategory: 'functional',
            },
            {
              title: 'Analytics Cookies',
              description:
                'We use cookies to see how users user our websites. For instance, we may look at page visits and other relevant data.',
              linkedCategory: 'analytics',
            },
          ],
        },
      },
    },
  },
};