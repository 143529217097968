import * as React from 'react';
import { ScreenerBannerContext } from './ScreenerBannerContext';

export default function useScreenerBannerContext() {
  const context = React.useContext(ScreenerBannerContext);
  if (!context) {
    throw new Error(
      'An error has occured while using the useScreenerBannerContext',
    );
  }
  return context;
}
