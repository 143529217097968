import { EventProperties } from '@constants/analytics';
import { isProduction } from '@utilities/common/Path';
import mixpanel from 'mixpanel-browser';
import { userHasConsentedForMixpanel } from '../../../shared/complaince/GDPR/utils';

export type TrackFunction = (
  event: string,
  properties: Record<string, string>,
) => void;

interface UseTrackingReturns {
  track: TrackFunction;
  user: {
    initialize: (userId: string) => void;
    setProperties: (
      properties: Record<string, string>,
      setMode?: 'once' | 'overwrite' | 'append',
    ) => void;
  };
}

function useTracking(): UseTrackingReturns {
  const track = (
    event: string = '',
    properties: Record<keyof typeof EventProperties, string> | {} = {},
  ) => {
    if (!isProduction()) {
      console.log({ event, properties });
    }
    if (userHasConsentedForMixpanel()) {
      mixpanel.track(event, properties);
    }
  };

  const initializeUser = (userId: string) => {
    if (userHasConsentedForMixpanel()) {
      mixpanel.identify(userId);
    }
  };

  const setUserProperties = (
    properties: Record<string, string>,
    setMode: 'once' | 'overwrite' | 'append' = 'once',
  ) => {
    if (userHasConsentedForMixpanel()) {
      switch (setMode) {
        case 'once':
          mixpanel.people.set_once(properties);
          break;

        case 'overwrite':
          mixpanel.people.set(properties);
          break;

        case 'append':
          mixpanel.people.append(properties);
          break;

        default:
          mixpanel.people.set_once(properties);
      }
    }
  };

  return {
    track,
    user: { initialize: initializeUser, setProperties: setUserProperties },
  };
}

export default useTracking;
