import NetworkClient from '@services/Network';
// import { ConfigurationVariables } from '@constants/variables';
// import { ThunkDispatch } from 'redux-thunk';
import Config from '../config';

const ErrorMessages = {
  generic: 'Unable to authenticate the user, please try again!',
};

const client = new NetworkClient();

export const requestOTP = async (body: { email: string; accessCode?: string }) => {
  const response = await client.doPost(Config.api.auth.otpRequest, body);
  console.log({ response });
  if (response.success) {
    return true;
  }

  throw new Error(
    response.error?.message || response.details || ErrorMessages.generic,
  );
};

export const verifyOTP = async (body: {
  email: string;
  otp: string;
  accessCode: string;
})  => {
  const response = await client.doPost(Config.api.auth.otpVerify, body);
  if (response.success) {
    // dispatch({
    //   type: 'SET_CONFIG_VARIABLES',
    //   data: response.data?.config?.MAVERICK_CONFIG
    //     ? ConfigurationVariables[response.data.config.MAVERICK_CONFIG]
    //     : ConfigurationVariables.care,
    // });
    return response.data;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const signOut = async () => {
  const response = await client.doPost(Config.api.auth.logout, {});
  if (response.success) {
    return response.success;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};
