import NetworkClient from '@services/Network';
import { beautifyUrl } from '@utilities/common/Path';
import Config from '../config';

const client = new NetworkClient();

const ErrorMessages = {
  genericGet:
      'Unable to fetch the details, please try again!',
};

export const getPersonalInsightsResultList = async (sessionId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.personalInsights.getResultList, [sessionId]),
  );
  if (response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || response.details || ErrorMessages.genericGet,
  );
};

export const getPersonalInsightsResultById = async (resultId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.personalInsights.getResult, [resultId]),
  );
  if (response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || response.details || ErrorMessages.genericGet,
  );
};

