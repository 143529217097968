import { encodeWithBase64, decodeWithBase64 } from './Encryption';
// import { RootState } from '../../shared/redux/reducer';
import { StorageItems } from '../../shared/constants/App';


export const SessionStorageKeys = {
  MISC: {
    GDPR_CONSENT_UPDATED: 'GDPR_CONSENT_UPDATED',
    SYNC_GDPR_CONSENT_WITH_USER: 'SYNC_GDPR_CONSENT_WITH_USER',
  },
};


export const addItemToLocalStorage = (
  key: string,
  item: string | { [key: string]: any } | { [key: string]: any }[],
): void => {
  const encodedKey = encodeWithBase64(key);
  const preProcessedItem =
    typeof item === 'string' ? item : JSON.stringify(item);
  const encodedItem = encodeWithBase64(preProcessedItem);

  localStorage.setItem(encodedKey, encodedItem);
};

export const getItemFromLocalStorage = (
  key: string,
  itemType: 'string' | 'object',
): string | { [key: string]: any } => {
  const encodedKey = encodeWithBase64(key);
  const retrievedItem = localStorage.getItem(encodedKey);
  if (retrievedItem) {
    const decodedItem =
      itemType === 'string'
        ? decodeWithBase64(retrievedItem)
        : JSON.parse(decodeWithBase64(retrievedItem));

    return decodedItem;
  }

  return itemType === 'string' ? '' : {};
};

export const removeItemFromLocalStorage = (key: string) => {
  const encodedKey = encodeWithBase64(key);
  localStorage.removeItem(encodedKey);
};

// export const saveAppState = (state: RootState) => {
//   addItemToLocalStorage(StorageItems.ROOT_STATE, state);
// };

// export const loadAppState = () =>
//   getItemFromLocalStorage(StorageItems.ROOT_STATE, 'object') as RootState;

export const clearAppState = () => {
  removeItemFromLocalStorage(StorageItems.ROOT_STATE);
};
