import React from 'react';
import './ScreenerBanner.scss';
import closeIcon from '../../../assets/screener-banner-close-icon.svg';

interface IBannerProps {
  buttonTitle?: string;
  text: string;
  onButtonClick?: () => void;
  onClose?: () => void;
}

function ScreenerBanner({ text, buttonTitle, onButtonClick, onClose }: IBannerProps) {
  return (
    <div className="banner">
      <div className="banner-content">
        <p className="banner-text">{text}</p>
        {buttonTitle && <button type="button" className="banner-button" onClick={onButtonClick}>
          {buttonTitle}
        </button>}
      </div>
      {onClose && <img src={closeIcon} alt="close" className="banner-close" onClick={onClose} />}
    </div>
  );
}

export default ScreenerBanner;
