import * as React from 'react';
import useTracking from '@hooks/useTracking';
import OnsiteCardLayout from '@components/OnsiteCardLayout';
import calendar from '@assets/onsite-calendar.svg';
import locationPin from '@assets/location-pin.svg';
import Typography from '@widgets/Typography';
import moment from 'moment';
import './OnsiteEventHome.scss';
import { useNavigate } from 'react-router-dom';
import useOnsiteData, {
  useAllBookings,
} from '@hooks/useOnsiteData/useOnsiteData';
import { ProviderRoles } from '@constants/provider';
import {
  getOnsiteEventTimings,
  getTimeZone,
  slotTo15MinTime,
} from '@utilities/common/Date';
import { getImageUri } from '@utilities/common/Path';
import NoDataPlaceholder from '@components/NoDataPlaceholder';
import Loader from '@widgets/Loader';
import { MeetingStatus, OnEventSiteStatus } from '@constants/meetingStatus';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import CountrySpecificDropdown from '@widgets/CountrySpecificDropdown/CountrySpecificDropdown';
import { getCityFromLocation, getCityIdFromName, getCityNameFromId } from '@utilities/helpers/locationOfResidence';
import useAuth from '@hooks/useAuth';
import { Country } from '../../../shared/locationList';
import useProfileDetails from '../Provider/Home/hooks/useProfileDetails';
import OnsiteUpcomingSessions from './OnsiteUpcomingSession';
import useScreenerBannerContext from '../../components/ScreenerBanner/context/useScreenerBannerContext';

export default function OnsiteEventHome() {
  const { track } = useTracking();
  const { user } = useAuth();
  const { data: profileData, isLoading: isLoadingProfile } = useProfileDetails({
    refetchOnWindowFocus: false,
  });
  const [onsiteLocValue, setOnsiteLocValue] = React.useState<number[]>([]);

  const { updateMeetingList } = useScreenerBannerContext();

  const { misc, phoneNumber } = profileData || {};
  const navigate = useNavigate();
  const {
    onsiteEvents,
    isOnsiteEventsLoading,
    locationData,
    isLocationsLoading,
  } = useOnsiteData(onsiteLocValue.length ? onsiteLocValue : undefined);
  const filters = [
    {
      field: 'status',
      value: [MeetingStatus.scheduled],
    },
    {
      field: 'role',
      value: [ProviderRoles.ONSITE],
    },
  ];
  const { meetings, isLoading, refetchAllBookings, isFetching } = useAllBookings(filters);


  React.useEffect(() => {
    updateMeetingList(meetings);
  }, [isLoading, meetings.length, isFetching]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (user?.isEmployee) {
        refetchAllBookings();
      }
    }, 900000);
  
    return () => clearInterval(interval);
  }, []);

  const transformApiResponse = (data: Country[]) =>  data.map(country => {
    const cityMap = new Map();

    country.city.forEach(city => {
      if (cityMap.has(city.name)) {
        cityMap.get(city.name).id.push(city.id);
      } else {
        cityMap.set(city.name, { name: city.name, id: [city.id] });
      }
    });

    return {
      ...country,
      city: Array.from(cityMap.values()),
    };
  }).sort((previousCountry, nextCountry) => 
    previousCountry.country.localeCompare(nextCountry.country));

  const transformedLocationData =  React.useMemo(() => {
    if (locationData?.locations) {
      return transformApiResponse(locationData?.locations as Country[]);
    }
    return [];
  }, [locationData?.locations]);
  
  React.useEffect(() => {
    if (misc && misc.region && transformedLocationData && transformedLocationData.length) {
      const city = getCityFromLocation(misc.region, transformedLocationData) || '';
      const cityId = getCityIdFromName(city, transformedLocationData);
      if (cityId) {
        setOnsiteLocValue(
          cityId,
        );
      }
    }
  }, [misc, locationData, transformedLocationData]);

  const activeOnsiteEvents = React.useMemo(
    () =>
      onsiteEvents?.deployments.filter(
        (event) => event.status === OnEventSiteStatus.LIVE,
      ),
    [onsiteEvents],
  );
  if (
    isOnsiteEventsLoading ||
    isLocationsLoading ||
    isLoadingProfile ||
    isLoading
  ) {
    return <Loader useFullPage withBackdrop />;
  }
  return (
    <article className="onsite-event-home">
      {meetings && meetings?.length >= 1 && (
        <OnsiteUpcomingSessions
          meetingDetails={meetings}
          phoneNumber={phoneNumber}
          onReschedule={refetchAllBookings}
        />
      )}
      <section className="header-onsite">
        <section className="left-content">
          <Typography size={24} weight="400">
            Discover onsite days near you
          </Typography>
        </section>
        <section className="right-content">
          <CountrySpecificDropdown
            data={transformedLocationData}
            selectedValue={onsiteLocValue && onsiteLocValue.length ? getCityNameFromId(onsiteLocValue, transformedLocationData) : ''}
            fontSize={13}
            placeholder="Choose location"
            onChange={(value) =>{
              const cityId = getCityIdFromName(value, transformedLocationData);
              if (cityId) {
                setOnsiteLocValue(cityId);
                track(EventNames.location, {
                  eventAction: EventActions.click,
                  eventCategory: EventCategories.location,
                  eventLabel: 'location',
                  userType: Analytics.getUserType(),
                  eventSource: 'onsite',
                  eventValue: value,
                });
              }
            }
            }
            disclaimerHidden
            inlineSearch
            minWidth="170px"
            dropDownMinWidth="220px"
            placement="left"
          />
        </section> 
      </section>


      {activeOnsiteEvents && activeOnsiteEvents.length >= 1 ? (
        <section className="grid-container">
          {activeOnsiteEvents.map(
            ({ location, provider, events, fromDate, id, fromTime }) => (
              <OnsiteCardLayout
                isDeployementCard
                cardTitle={`Onsite Day - ${location?.city}`}
                dateIcon={calendar}
                locationIcon={locationPin}
                onClick={() => navigate(`/onsite-days/${id}`)}
                eventLocation={location}
                eventTimings={getOnsiteEventTimings(fromDate, fromTime)}
                eventDate={moment
                  .utc(
                    `${fromDate} ${slotTo15MinTime(+(fromTime || '0'))}`,
                    'YYYY-MM-DD HH:mm',
                  )
                  .tz(getTimeZone())
                  .format('DD')}
                eventMonth={moment
                  .utc(
                    `${fromDate} ${slotTo15MinTime(+(fromTime || '0'))}`,
                    'YYYY-MM-DD HH:mm',
                  )
                  .tz(getTimeZone())
                  .format('MMM')}
                providerAvatar={getImageUri(provider?.profileImage as string)}
                providerName={provider?.name}
                providerSpeciality={provider?.expertise?.join(', ')}
                events={events}
                isLoading={
                  isOnsiteEventsLoading ||
                  isLocationsLoading ||
                  isLoadingProfile
                }
              />
            ),
          )}
        </section>
      ) : (
        <NoDataPlaceholder />
      )}
    </article>
  );
}
