import useTracking from '@hooks/useTracking';
import Analytics from '@services/Analytics';
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { SessionStorageKeys } from '@utilities/common/Storage';
import { consentTimestamp } from '@constants/App';
import moment from 'moment';
import { getCookie } from 'vanilla-cookieconsent';
import { useUpdateGDPRConsent } from './view/screens/OnboardingForm/hooks/useConsent';
import useAuth from './utilities/hooks/useAuth';

function ProtectedRoutes() {
  const { isLoggedIn, user: userDetails } = useAuth();
  const redirectUrl = window.location.pathname;
  const { user } = useTracking();

  const { mutate: updateGDPRConsent } = useUpdateGDPRConsent();

  const setupUserProfileForAnalytics = () => {
    const { id, createdAt, updatedAt } = userDetails;
    user.initialize(id);
    user.setProperties({
      userId: id,
      created: createdAt,
      updated: updatedAt,
      userType: Analytics.getUserType(),
    });
  };

  const shouldUpdateGDPRConsent = sessionStorage.getItem(SessionStorageKeys.MISC.GDPR_CONSENT_UPDATED);
  const shouldSyncGDPRPreference = sessionStorage.getItem(SessionStorageKeys.MISC.SYNC_GDPR_CONSENT_WITH_USER);

  if (shouldUpdateGDPRConsent || shouldSyncGDPRPreference) {
    const currentPreferences = getCookie();

    updateGDPRConsent({
      country: userDetails?.profile?.country || '',
      timestamp: moment().format(consentTimestamp),
      reference: currentPreferences.consentId,
      metadata: currentPreferences,
    });

    sessionStorage.removeItem(SessionStorageKeys.MISC.SYNC_GDPR_CONSENT_WITH_USER);
    sessionStorage.removeItem(SessionStorageKeys.MISC.GDPR_CONSENT_UPDATED);
  }

  React.useEffect(() => {
    if (isLoggedIn) {
      setupUserProfileForAnalytics();
    }
  }, []);

  return isLoggedIn ? <Outlet /> : <Navigate to={`/login?redirectUrl=${redirectUrl}`} />;
}

export default ProtectedRoutes;
