export interface City {
  id?: number[];
  name: string;
  code: string;
}
  
export interface Country {
  id?: number;
  country: string;
  code: string;
  city: City[];
}
  
export const LORList: Country[] = [
  {
    country: 'Brazil',
    code: 'BR',
    city: [{ name: 'São Paulo', code: 'SAO' }],
  },
  {
    country: 'Mexico',
    code: 'MX',
    city: [
      { name: 'Mexico City', code: 'MEX' },
    ],
  },
  {
    country: 'USA',
    code: 'US',
    city: [
      { name: 'Austin, TX', code: 'AUS' },
      { name: 'San Jose, CA', code: 'SJC' },
      { name: 'San Francisco, CA', code: 'SFO' },
      { name: 'Los Angeles, CA', code: 'LAX' },
      { name: 'New York, NY', code: 'NYC' },
      { name: 'Seattle, WA', code: 'SEA' },
      { name: 'Washington D.C.', code: 'WDC' },
    ],
  },
  {
    country: 'Ireland',
    code: 'IE',
    city: [{ name: 'Dublin', code: 'DUB' }],
  },
  {
    country: 'United Kingdom',
    code: 'GB',
    city: [{ name: 'London', code: 'LON' }],
  },
  {
    country: 'Morocco',
    code: 'MA',
    city: [{ name: 'Casablanca', code: 'CAS' }],
  },
  {
    country: 'Germany',
    code: 'DE',
    city: [{ name: 'Berlin', code: 'BER' }],
  },
  {
    country: 'Turkey',
    code: 'TR',
    city: [{ name: 'Istanbul', code: 'IST' }],
  },
  {
    country: 'Poland',
    code: 'PL',
    city: [{ name: 'Warsaw', code: 'WAW' }],
  },
  {
    country: 'Singapore',
    code: 'SG',
    city: [{  name: 'Singapore', code: 'SIN' }],
  },
  {
    country: 'India',
    code: 'IN',
    city: [
      {  name: 'Gurgaon', code: 'GGN' },
      {  name: 'Mumbai', code: 'BOM' },
    ],
  },
  {
    country: 'Indonesia',
    code: 'ID',
    city: [{  name: 'Jakarta', code: 'JKT' }],
  },
  {
    country: 'Malaysia',
    code: 'MY',
    city: [{  name: 'Kuala Lumpur', code: 'KUL' }],
  },
  {
    country: 'Philippines',
    code: 'PH',
    city: [{  name: 'Manila', code: 'MNL' }],
  },
  {
    country: 'South Korea',
    code: 'KR',
    city: [{ name: 'Seoul', code: 'SEL' }],
  },
  {
    country: 'Thailand',
    code: 'TH',
    city: [{ name: 'Bangkok', code: 'BKK' }],
  },
  {
    country: 'Vietnam',
    code: 'VN',
    city: [{ name: 'Ho Chi Minh City', code: 'HCM' }],
  },
  {
    country: 'Japan',
    code: 'JP',
    city: [{ name: 'Tokyo', code: 'TYO' }],
  },
  {
    country: 'China',
    code: 'CN',
    city: [{ name: 'Shanghai', code: 'SHA' }, { name: 'Beijing', code: 'BJS' }],
  },
].sort((previousCountry, nextCountry) => 
  previousCountry.country.localeCompare(nextCountry.country));
