import * as React from 'react';

import { useLocation } from 'react-router-dom';
import usePaths from '@hooks/usePaths';
import { routesForPersonalInsightsBanner } from '@components/Layout/constant/LayoutConstant';
import { IOnsiteMeetingResponse, ISoonestMeeting } from '@hooks/useOnsiteData/types';
import useAuth from '@hooks/useAuth';
import { getItemFromLocalStorage } from '@utilities/common/Storage';
import { StorageItems } from '@constants/App';
import { ScreenerBannerContext } from './ScreenerBannerContext';


export interface IScreenerBannerProvider {
  children: React.ReactNode;
}

export function ScreenerBannerContextProvider({ children }: IScreenerBannerProvider) {
  const [showPersonalInsightsBanner, setShowPersonalInsightsBanner] = React.useState<boolean>(false);
  const [currentMeeting, setCurrentMeeting] = React.useState<ISoonestMeeting | null>(null);
  const [meetings, setMeetings] = React.useState([] as IOnsiteMeetingResponse['meetings']);
  const { user } = useAuth();

  const [firstPath] = usePaths();
  const location = useLocation();

  const updatePersonalInsightsBannerVisibility = (
    status: boolean,
  ) => {
    setShowPersonalInsightsBanner(status);
  };

  const updateCurrentMeeting = (
    meeting: ISoonestMeeting,
  ) => {
    setCurrentMeeting(meeting);
  };


  const updateMeetingList = (
    meetingList : IOnsiteMeetingResponse['meetings'],
  ) => {
    setMeetings([...meetingList]);
  };


  React.useEffect(() => {
    if (routesForPersonalInsightsBanner.includes(firstPath) && !!user?.isEmployee) {
      
      const now = Date.now();

      const meetingsWithin24Hours = meetings.map((meeting: { scheduledStartTime : string }) => ({
        ...meeting,
        timeDiff: (Number(meeting.scheduledStartTime)  * 1000 - now),
        timeDiffInHours: (Number(meeting.scheduledStartTime)  * 1000 - now) / (1000 * 60 * 60),
      }))
        .filter((meeting: { timeDiff: number; }) => meeting.timeDiff > 0 && meeting.timeDiff <= 24 * 60 * 60 * 1000)
        .sort((a: { timeDiff: number; }, b: { timeDiff: number; }) => a.timeDiff - b.timeDiff);

      if (meetingsWithin24Hours && meetingsWithin24Hours.length === 0) {
        updatePersonalInsightsBannerVisibility(false);
        return;
      }
      
      const soonestMeeting = meetingsWithin24Hours[0] as ISoonestMeeting;

      const lastDismissedData = getItemFromLocalStorage(StorageItems.BANNER_DISMISSED, 'object') as {
        meetingTime: string,
        dismissedAt: number,
        hideDuration: number,
      };

      if (lastDismissedData.meetingTime === soonestMeeting.scheduledStartTime) {
        const hideUntil = lastDismissedData.dismissedAt + lastDismissedData.hideDuration;
        if (now < hideUntil) {
          updatePersonalInsightsBannerVisibility(false);
          return;
        }
      }
     
      if (currentMeeting?.scheduledStartTime !== soonestMeeting.scheduledStartTime) {
        updateCurrentMeeting(soonestMeeting);
      }
      
      updatePersonalInsightsBannerVisibility(true);
      
    } 
    
  }, [meetings, location.pathname]);



  const filterContextValues = React.useMemo(
    () => ({
      showPersonalInsightsBanner,
      currentMeeting,
      updatePersonalInsightsBannerVisibility,
      updateMeetingList,
    }),
    [showPersonalInsightsBanner, currentMeeting, updatePersonalInsightsBannerVisibility, updateCurrentMeeting],
  );

  return (
    <ScreenerBannerContext.Provider value={filterContextValues}>
      {children}
    </ScreenerBannerContext.Provider>
  );
}
