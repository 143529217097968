import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CssSize } from '@widgets/ClickableCard/types';
import DisclaimerCard from '@widgets/DisclaimerCard';
import Typography from '../Typography';
import chevronIcon from './assets/placeholder.svg';
import InfoCircle from './assets/InfoCircle';
import seachIcon from './assets/search.svg';
import tickIcon from './assets/tick.svg';
import './CountrySpecificDropdown.scss';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';

interface City {
  id?: number[];
  name: string;
  code: string;
}

interface Country {
  id?: number;
  country: string;
  code: string;
  city: City[];
}

interface ICountrySpecificDropdown {
  data: Country[];
  onChange?: (value: string) => void;
  selectedValue: string | null;
  disabled?: boolean;
  fontSize?: number;
  fullWidth?: boolean;
  placeholder?: string;
  minWidth?: CssSize,
  disclaimerHidden?: boolean,
  inlineSearch?: boolean,
  dropDownMinWidth?: CssSize,
  placement?: 'left' // add more variants here
  dropdownClassname?: string,
}

function CountrySpecificDropdown({
  data,
  onChange,
  selectedValue,
  disabled = false,
  fontSize = 12,
  fullWidth = false,
  placeholder = '',
  minWidth,
  inlineSearch = false,
  disclaimerHidden = false,
  dropDownMinWidth,
  placement,
  dropdownClassname,
}: ICountrySpecificDropdown) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [showItems, setShowItems] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  useOutsideClickHandler(parentRef, () => setShowItems(false));

  const onDropdownItemClick = (value: string) => {
    setShowItems(false);
    setSearchTerm('');
    onChange?.(value);
  };

  const getStyles = () => {
    const styles: React.CSSProperties = {
      minWidth,
    };
    return styles;
  };
  
  const getDropDownStyles = () => {
    const styles: React.CSSProperties = {
      minWidth: dropDownMinWidth,
    };
    return styles;
  };

  const highlightSearchTerm = (text: string, searchText: string) => {
    if (!searchText) return text;

    const regex = new RegExp(`^(${searchText})`, 'i');

    const match = text.match(regex);
    if (!match) return text;

    return (
      <>
        <strong>{match[0]}</strong>
        {text.slice(match[0].length)}
      </>
    );
  };


  const filteredData = data
    .map((country) => {
      const matchesCountry = country.country.toLowerCase().startsWith(searchTerm.toLowerCase());
      const filteredCities = country.city.filter((city) =>
        city.name.toLowerCase().startsWith(searchTerm.toLowerCase()),
      );

      return {
        ...country,
        city: matchesCountry ? country.city : filteredCities,
      };
    })
    .filter((country) => country.city.length > 0 || country.country.toLowerCase().startsWith(searchTerm.toLowerCase()));

  const noResults = searchTerm.length > 0 && filteredData.length === 0;

  return (
    <div ref={parentRef} className={`country-specific-select-box ${disabled ? 'disabled' : ''}`} style={fullWidth ? { width: '100%' } : getStyles()}>
      <div
        className={`country-specific-placeholder-container ${disabled ? 'country-specific-disabled' : ''}`}
        role="button"
        tabIndex={0}
        onClick={() => {
          if (!disabled) {
            setShowItems(() => true);
          }
        }}
      >
        {inlineSearch || !showItems ? (
          <>
            <div className="country-specific-placeholder-text">
              {selectedValue ? (
                <Typography size={fontSize} weight="400">
                  {selectedValue}
                </Typography>
              ) : (
                <Typography size={fontSize} weight="400" color="secondary">
                  {placeholder}
                </Typography>
              )}
            </div>
            {!disabled && <img className="chevron" src={chevronIcon} alt="select" />}
          </>
        ) : (
          <>
            <img className="chevron" src={seachIcon} alt="select" height={14} />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="country-specific-dropdown-search"
              placeholder={placeholder}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <img className="chevron" src={chevronIcon} alt="select" />
          </>
        )}
      </div>

      <AnimatePresence mode="wait">
        {showItems && (
          <motion.div
            className={`country-specific-v2-dropdown ${placement === 'left' ? 'shift-left' : ''} ${dropdownClassname}`}
            style={fullWidth ? { width: '100%' } : getDropDownStyles()}
            initial={{ opacity: 0, top: '0px' }}
            animate={{ opacity: 1, top: 'calc(100% + 4px)' }}
            exit={{ opacity: 0, top: '0px' }}
            transition={{ duration: 0.3 }}
          >
            {!disclaimerHidden && <DisclaimerCard
              subheading="Find clinicians in your current location to ensure accurate availability of services and compliance to local regulations."
              icon={<div style={{ color: '#64481D' }}>
                <InfoCircle />
              </div>}
              link={{ text: 'Terms of Service', href: 'https://intellect.co/terms-of-service/' }}
              cardVariant="info"
              margin="0px"
              padding="10px"
              subHeadingFontSize={11}
              fontColor="#64481D"
              borderRadius="8px 8px 0px 0px"
              customClass="country-specific-dropdown-disclaimer"
            />}
            {inlineSearch &&
              <div className="country-specific-dropdown-search-container">
                <img src={seachIcon} alt="Search" className="search-icon" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="country-specific-dropdown-search"
                  placeholder="Search in locations"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
              </div>}


            <div className="country-specific-dropdown-list">
              {noResults ? (
                <div className="country-specific-no-results">
                  <Typography size={fontSize} weight="400" color="secondary">
                    No data found
                  </Typography>
                </div>
              ) : (
                filteredData.map(({ country, code, city }) => (
                  <div key={code}>
                    <div className="country-specific-dropdown-country">
                      <Typography size={fontSize} weight="400" withColor="#767D83">
                        {country}
                      </Typography>
                    </div>

                    {city.map(({ name }, index) => {
                      const value = `${name}`;
                      return (
                        <motion.div
                          id={`option-${code}-${index}`}
                          className={`country-specific-dropdown-item ${value === selectedValue ? 'selected' : ''}`}
                          role="button"
                          tabIndex={0}
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${code}-${index}`}
                          onClick={() => onDropdownItemClick(value)}
                          initial={{ backgroundColor: '#fff' }}
                          whileHover={{ backgroundColor: 'rgba(233, 236, 238, 0.5)' }}
                          animate={value === selectedValue ? { backgroundColor: 'rgb(233, 236, 238)' } : {}}
                          transition={{ duration: 0.3 }}
                        >
                          <Typography size={fontSize} weight="400" withColor="#101828">
                            {highlightSearchTerm(name, searchTerm)}
                          </Typography>
                          {value === selectedValue && (
                            <motion.img src={tickIcon} alt="selected" initial={{ scale: 0 }} animate={{ scale: 1 }} />
                          )}
                        </motion.div>
                      );
                    })}
                  </div>
                ))
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default CountrySpecificDropdown;