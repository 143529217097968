export const APP_NAME = 'maverick';
export const APP_CONTEXT = 'responder';
export const APP_DEFAULT_LANGUAGE = 'en';

export enum StorageItems {
  USER_INFO = 'userInfo',
  CHAT_TOKEN = 'chatToken',
  ROOT_STATE = 'rootState',
  APP_LANGUAGE = 'appLanguage',
  TIME_ZONE = 'timeZone',
  BANNER_DISMISSED = 'bannerDismissed',
  IRIS_EXPIRY_TIME = 'irisExpiryTime',
  IRIS_ACCESS_TOKEN = 'irisAccessToken',
}

export const VideoRoomSDKs = {
  twilio: '0',
  dailyCo: '5',
  intellectClinicOffsite: '3',
  default: '0', //* SDK type other than defined type will fall back to type 0 ie., twilio
} as const;

export const BookingSources = {
  maverick: '3',
  responder: '4',
  carenav: '6',
} as const;

export enum WeekdayEnum {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum PopOver {
  MarbleIFrame = 'marble',
}


export const consentTimestamp = 'YYYY-MM-DD HH:mm:ss';
