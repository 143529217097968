import Popup from '@widgets/Popup';
import Typography from '@widgets/Typography';
import * as React from 'react';
import './WarningModal.scss';
import Button from '@widgets/Button';
import WarningModalSvg from '../../../../assets/warningModal.svg';

interface IWarningProps {
  onCancel: () => void;
  onConfirm: () => void;
}

function WarningModal({
  onCancel,
  onConfirm,
}: IWarningProps) {

  return (
    <Popup showCloseIcon={false} onClose={onCancel} className="warning-modal">
      <section className="warning-modal__container">
        <img src={WarningModalSvg} alt="warning-exiting-screener" />
        <section className="warning-modal__header">
          <Typography size={24} weight="600" withColor="#101828">
          You’re almost there...
          </Typography>
          <Typography size={14} weight="400" withColor="#475467" additionalStyles={{ marginBlock: '25px' }}>
          The screener only takes 5 minutes to complete. 
          </Typography>
        </section>
        <section className="warning-modal__body">
          <Button
            label="Continue attempt"
            onClick={onConfirm}
          />
          <Button label="Quit attempt" variant="transparent" onClick={onCancel} />
        </section>
      </section>
    </Popup>
  );
}

export default WarningModal;
